import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_blog.js";
import { Image, IPhone, img } from "../../components";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p className="text-xl leading-9">
  There are small changes and improvements to Trailguide almost every day that
  you might not notice, but which makes the experience a little better. However,
  every spring we bring you a new edition of Trailguide. Check out what is new
  this spring which can make your mountain biking experience even better than
  before.
    </p>
    <br />
    <br />
    <div className="flex justify-around">
  <IPhone alt="Easily mark individual trails as ridden or add them to your favorites" src={img("/trailguide/2024/iphone-lists-add.jpg")} className="w-64" text="Easily mark individual trails as ridden or add them to your favorites" mdxType="IPhone" />
  <IPhone alt="All your lists are available in one place" src={img("/trailguide/2024/iphone-lists-mylists.jpg")} className="w-64" text="All your lists are available in your user page, accessible from the main menu" mdxType="IPhone" />
  <IPhone alt="A list gives you a nice overview of the chosen trails" src={img("/trailguide/2024/iphone-lists-view.jpg")} className="w-64" text="A list gives you a nice overview of your selected trails" mdxType="IPhone" />
    </div>
    <br />
    <br />
    <h3>{`Create lists of trails and tours for any purpose`}</h3>
    <p className="text-base leading-7">
  If you ever browsed around in Trailguide and came upon a trail that you really
  wanted to ride, but then forgot about it and what the name was? Now we have a
  solution for that. Easily create "playlists" in Trailguide and add any trail
  to it so you can access it later.
  <br />
  <br />
  You can mark any trail you have ridden as ridden to keep track of them. You can
  add trails to a favorites list for other people to see which ones you really liked.
  <br />
  <br />
  We have much more planned on the road map for this feature, but we are so
  excited we wanted to get it out the door as quickly as we had something useful
  for you.
    </p>
    <br />
    <br />
    <h3>{`Find out what your trail is made of`}</h3>
    <p className="text-base leading-7">
  We all want really nice dirt trails, right? When looking for trails to ride,
  it might be difficult from just looking at the map and the track what type of
  trail it is. Is it a singletrack, a dirt road, or does it consist of gravel or
  tarmac?
  <br />
  <br />
  When inspecting a trail in the app, you can now show the waytype and surface type
  of the trail and where it is. Just click on the map layer symbol in the map and
  turn on "Waytypes".
  <br />
  <br />
  Mouse over the segments to see a popup with a description of the waytype.
  <br />
  <br />
  Click the "i" icon below the map layer icon to show the legend with an explanation
  of the different waytypes.
    </p>
    <Image src="/trailguide/2024/track-waytypes-desktop.jpg" mdxType="Image" />
    <br />
    <br />
    <h3>{`The lay of the land`}</h3>
    <p className="text-base leading-7">
  In addition to the height profile in the trail description map we also added a
  way to visualize the elevation of the track on the track itself. Just click on
  the map layer symbol in the map and turn on "Elevation".
    </p>
    <Image src="/trailguide/2024/track-elevation-heat.jpg" mdxType="Image" />
    <br />
    <br />
    <h3>{`Update 2023`}</h3>
    <p className="text-base leading-7">
  See last years update with many useful features to find the best MTB
  singletracks:
    </p>
    <p><a parentName="p" {...{
        "href": "/news/trailguide_2024_spring_edition/"
      }}>{`Update 2023`}</a></p>
    <br />
    <br />
    <Image src="/news/tg_2023_spring_edition.jpg" className="mx-auto mt-12 mb-4" mdxType="Image">
  Last years update brought many useful features.
    </Image>
    <br />
    <p><a parentName="p" {...{
        "href": "/howto/install/"
      }}>{`Install Trailguide`}</a>{` |
`}<a parentName="p" {...{
        "href": "/howto/gps-on-iphone/"
      }}>{`Make GPS work on iPhone`}</a>{` |
`}<a parentName="p" {...{
        "href": "/howto/navigation/"
      }}>{`Navigation on iPhone`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      